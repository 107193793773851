import axios from 'axios'

const getData = async (uri, _items, _totalItems, _loading, _options, search, customFilter = '') => {
  const items = _items
  const totalItems = _totalItems
  const options = _options
  const loading = _loading

  loading.value = true
  try {
    const sorting = options.value.sortBy !== undefined && options.value.sortBy.length > 0
      ? `${options.value.sortBy[0]} ${(options.value.sortDesc[0] ? 'asc' : 'desc')}`
      : 'created_at ASC'
    const response = await axios.get(`${uri}?sort=${sorting}&page=${options.value.page}&itemsPerPage=${options.value.itemsPerPage}&search=${search.value}&filter=${customFilter}`)

    const {
      page,
      itemsPerPage,
    } = options.value
    const result = response.data.rows
    const total = response.data.totalRecords
    let rs = result

    items.value = rs
    totalItems.value = total

    // if (sortBy.length === 1 && sortDesc.length === 1) {
    //   rs = rs.sort((a, b) => {
    //     const sortA = a[sortBy[0]]
    //     const sortB = b[sortBy[0]]

    //     if (sortDesc[0]) {
    //       if (sortA < sortB) return 1
    //       if (sortA > sortB) return -1

    //       return 0
    //     }

    //     if (sortA < sortB) return -1
    //     if (sortA > sortB) return 1

    //     return 0
    //   })
    // }

    if (itemsPerPage > 0) {
      rs = rs.slice((page - 1) * itemsPerPage, page * itemsPerPage)
    }

    loading.value = false
  } catch (error) {
    // console.log(error)
    loading.value = false
  }
}

export default getData
